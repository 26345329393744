import React from 'react';
export const YoutubePlayLists = () => {
    return (
        <div className="card border-dark mb-3">
            <div className="card-header">
                Youtube 系列影片
            </div>
            <div className="row mx-1">
                <div className="col-12">
                    <div className="row py-2">
                        <div className="col-6">
                            <a href="http://feis.studio/c">C 語言入門</a>
                        </div>
                        <div className="col-6">
                            <a href="https://feis.studio/leetcode30">C 語言的 LeetCode 四月挑戰</a>
                        </div>                
                    </div>                         
                    <div className="row py-2">
                        <div className="col-6">
                            <a href="https://feis.studio/leetcode_may">C 語言的 LeetCode 五月挑戰</a>
                        </div>
                        <div className="col-6">
                            <a href="https://feis.studio/leetcode_june">Python 與 C 的 LeetCode 六月挑戰</a>
                        </div>
                    </div>                
                    <div className="row py-2">
                        <div className="col-6">
                            <a href="https://feis.studio/leetcode_july">Python、C++ 與 C 的 LeetCode 七月挑戰</a>
                        </div>
                        <div className="col-6">
                            <a href="https://feis.studio/python_chat">邊聊邊入門 Python 程式設計</a>
                        </div>
                    </div>                    
                    <div className="row py-2">
                        <div className="col-6">
                            <a href="https://feis.studio/leetcode_top150">C 語言的 LeetCode 面試精選150 題挑戰</a>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>)
}
